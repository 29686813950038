import * as React from 'react';

interface Props {
    x: number;
    y: number;
    id: string;
    addClass?: string;
}

export const GoogleMap: React.FC<Props> = ({x, y, id, addClass}) => {
    return (
        <iframe
            id={id}
            className={addClass || ""}
            src={`https://maps.google.co.jp/maps?output=embed&q=${x},${y}`}
            width="500"
            height="375"
            frameBorder="0"
            style={{border:"0"}}
            allowFullScreen={true}
        ></iframe>
    )
}