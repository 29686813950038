import * as React from 'react';
import 'scroll-behavior-polyfill';

interface Props {
    targetRef: React.RefObject<HTMLElement>;
    children: string;
}

const BtnToScroll: React.FC<Props> = ({targetRef, children}) => {
    const scrollToTargetPlace = (e: any) => {
        e.preventDefault();
        const rectTop = targetRef.current?.offsetTop;
        const buffer = window.innerHeight / (-6);
        if(rectTop) {
            const targetPosition = rectTop + buffer;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            })
        }
    }

    return (
        <a
            className="c-kaku-btn--confirm"
            onClick={scrollToTargetPlace}
        >{children}
        </a>
    )
}

export default BtnToScroll;