import { isLiveFinished } from "@/functions/isLiveFinished"

export const LIVE_STATUS = {
    UN_FINISHED: "UN_FINISHED",
    FINISHED: "FINISHED",
    SOLD_OUT: "SOLD_OUT",
} as const

export type LIVE_STATUS = (typeof LIVE_STATUS)[keyof typeof LIVE_STATUS]

/**
 * 指定されたパラメータを元にLIVE_STATUSを判定し返却
 * @param liveDate ライブ開催日
 * @param isSoldOut 売り切れかどうかフラグ
 * @return LIVE_STATUS
 */
export const getLiveStatus = (
    liveDate: Date,
    isSoldOut: boolean
): LIVE_STATUS => {
    if (isLiveFinished(liveDate)) {
        return LIVE_STATUS.FINISHED
    }
    if (isSoldOut) {
        return LIVE_STATUS.SOLD_OUT
    }

    return LIVE_STATUS.UN_FINISHED
}
