export function createQueryParams<T extends Partial<Record<string, string>>>(
    params: T
) {
    return Object.keys(params).length === 0
        ? ""
        : "?" +
              (Object.keys(params) as [keyof T])
                  .filter((key) => params[key])
                  .map((key) => `${key as string}=${params[key]}`)
                  .join("&")
}
