import { LivesDetail } from '@/components/pages/Lives/Detail'
import { graphql } from 'gatsby'

export default LivesDetail

export const query = graphql`
    query PageLivesDetail($slug: String!) {
        contentfulSite {
            siteHeroImage {
                gatsbyImageData(resizingBehavior: FILL)
            }
        }
        contentfulLives(slug: { eq: $slug }) {
            liveActs
            liveDate(formatString: "YYYY/MM/DD")
            liveImages {
                gatsbyImageData(resizingBehavior: FILL)
            }
            livePlace {
                lat
                lon
            }
            livePlaceName
            liveTitle
            liveIsSoldOut
            slug
            updatedAt(formatString: "YYYY/MM/DD")
            description
            liveTime
            bookingUrl
            liveContent {
                raw
                references {
                    ... on ContentfulAsset {
                        id
                        url
                        contentful_id
                    }
                    ... on ContentfulLives {
                        ...LivesInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                    ... on ContentfulNews {
                        ...NewsInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                    ... on ContentfulMusics {
                        ...MusicsInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                    ... on ContentfulVideos {
                        ...VideosInformation
                        contentful_id
                        internal {
                            type
                        }
                    }
                }
            }
        }
    }
`
