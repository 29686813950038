import * as React from "react"
import { PageProps } from "gatsby"

import { GlobalLayout } from "@/components/GlobalLayout"
import { Head } from "@/components/Head"
import { SectionWrap } from "@/components/sections/SectionWrap"
import { GoogleMap } from "@/components/GoogleMap"
import { ArticleSlider } from "@/components/Sliders"
import LinkButton from "@/components/btn/LinkButton"
import { TitleWithBgImg } from "@/components/TitleWithBgImg"
import { useImage } from "@/hooks/useImage"
import {
    ContentfulLivesLiveContent,
    PageLivesDetailQuery,
} from "@root/types/graphql-types"
import {
    ActsSection,
    ArticleContentSection,
    DateSection,
    LivePlaceSection,
    TimeSection,
} from "./LivesDetail.component"
import {
    ArticleContent,
    ArticleTitle,
    ArticleWrapper,
} from "@/components/Layouts/Article"
import { useExpandImg } from "@/hooks/useExpandImg/hook"
import { getLiveStatus } from "@/constants/LIVE_STATUS"
import { createLiveBookingUrl } from "@/components/pages/LiveBooking/url"

type Props = PageProps<PageLivesDetailQuery>

const { createRef } = React

export const LivesDetail: React.FC<Props> = ({ data }) => {
    const { getImageProps, Image } = useImage()
    const { openFromEvent, ExpandImg } = useExpandImg()
    const liveStatus = getLiveStatus(
        new Date(data.contentfulLives?.liveDate),
        data.contentfulLives?.liveIsSoldOut || false
    )
    const ref = createRef<HTMLDivElement>()

    const updatedAt = new Date(data.contentfulLives?.updatedAt)

    return (
        <GlobalLayout
            Head={
                <Head
                    title={data.contentfulLives?.liveTitle || "liveの記事"}
                    description={
                        data.contentfulLives?.description || "liveの記事です"
                    }
                />
            }
            CoverComponent={<ExpandImg />}
        >
            <SectionWrap
                addClass="--article"
                Title={
                    <TitleWithBgImg
                        title={"LIVE INFO"}
                        BgImage={
                            <Image
                                {...getImageProps(
                                    data.contentfulSite?.siteHeroImage
                                        ?.gatsbyImageData
                                )}
                            />
                        }
                    />
                }
            >
                <ArticleWrapper>
                    <ArticleTitle
                        badge={
                            liveStatus === "SOLD_OUT"
                                ? {
                                      text: "SOLD OUT",
                                      color: "crimson",
                                  }
                                : liveStatus === "FINISHED"
                                ? {
                                      text: "終了しました",
                                      color: "red",
                                  }
                                : undefined
                        }
                    >
                        {data.contentfulLives?.liveTitle || ""}
                    </ArticleTitle>
                    <ArticleContent
                        Header={
                            data.contentfulLives?.liveImages ? (
                                <ArticleSlider
                                    openExpandImg={openFromEvent}
                                    Images={data.contentfulLives?.liveImages.map(
                                        (image, i) => (
                                            <Image
                                                {...getImageProps(
                                                    image?.gatsbyImageData,
                                                    {
                                                        alt: `${
                                                            data.contentfulLives
                                                                ?.liveTitle
                                                        }のイメージ${i + 1}`,
                                                    }
                                                )}
                                            />
                                        )
                                    )}
                                />
                            ) : (
                                <p>この記事には画像がありません</p>
                            )
                        }
                        releaseDate={updatedAt}
                    >
                        <DateSection
                            liveDate={data.contentfulLives?.liveDate}
                        />
                        <TimeSection
                            liveDate={data.contentfulLives?.liveDate}
                            liveTime={
                                data.contentfulLives?.liveTime as
                                    | string
                                    | undefined
                            }
                        />
                        <ActsSection
                            liveActs={
                                data.contentfulLives?.liveActs as
                                    | string[]
                                    | undefined
                            }
                        />
                        <LivePlaceSection
                            livePlaceName={
                                data.contentfulLives?.livePlaceName as
                                    | string
                                    | undefined
                            }
                            placeMapRef={ref}
                        />
                        <ArticleContentSection
                            content={
                                data.contentfulLives
                                    ?.liveContent as ContentfulLivesLiveContent
                            }
                            imageOnClick={openFromEvent}
                        />

                        <div style={{ margin: "150px 0 40px" }}>
                            <LinkButton
                                url={
                                    data.contentfulLives?.bookingUrl
                                        ? data.contentfulLives?.bookingUrl
                                        : createLiveBookingUrl({
                                              slug:
                                                  data.contentfulLives?.slug ||
                                                  undefined,
                                          })
                                }
                                disable={liveStatus !== "UN_FINISHED"}
                                color="red"
                            >
                                チケットを予約する
                            </LinkButton>
                        </div>

                        {data.contentfulLives?.livePlace && (
                            <>
                                <div ref={ref} />
                                <GoogleMap
                                    x={data.contentfulLives.livePlace.lat || 0}
                                    y={data.contentfulLives.livePlace.lon || 0}
                                    id="google-map"
                                    addClass="p-article__map"
                                />
                            </>
                        )}
                    </ArticleContent>
                </ArticleWrapper>
            </SectionWrap>
        </GlobalLayout>
    )
}
