import { createQueryParams } from "@/functions/urlUtils"
import { StringParam, useQueryParam } from "use-query-params"

type LiveBookingUrlParams = {
    slug?: string
}

const liveBookingPath = "/live-booking"

export const createLiveBookingUrl = (
    params: LiveBookingUrlParams,
    option?: Partial<{ queryParamOnly: boolean }>
) => {
    const queryParams = createQueryParams(params)
    return option?.queryParamOnly ? queryParams : liveBookingPath + queryParams
}

const tokenKey = "token"

export function getTokenFromQueryParam() {
    const [token] = useQueryParam(tokenKey, StringParam)
    return token
}
