import React from "react"
import { formatDate } from "@/functions/formatDate"
import { createToken } from "@/functions/createToken"
import BtnToScroll from "@/components/btn/BtnToScroll"
import { ArticleRichTextContent } from "@/components/ArticleRichTextContent"
import { convToWeek } from "@/functions/convToWeek"
import {
    ContentfulAsset,
    ContentfulLives,
    ContentfulLivesLiveContent,
} from "@root/types/graphql-types"
import { ArticleSection } from "@/components/Layouts/Article"

type DateSectionType = {
    liveDate: ContentfulLives["liveDate"]
}

export const DateSection: React.FC<DateSectionType> = ({ liveDate }) => {
    const liveDateObj = new Date(liveDate)

    return (
        <ArticleSection title="日程">
            <time dateTime={formatDate(liveDateObj, "-")}>
                {formatDate(liveDateObj, "/")}({convToWeek(liveDateObj)})
            </time>
        </ArticleSection>
    )
}

type TimeSectionType = {
    liveDate: ContentfulLives["liveDate"]
    liveTime?: ContentfulLives["liveTime"]
}
export const TimeSection: React.FC<TimeSectionType> = ({
    liveDate,
    liveTime,
}) => {
    const liveDateObj = new Date(liveDate)
    const [openTime, startTime] = liveTime?.split(",") || ["", ""]

    return (
        <ArticleSection title="時間">
            {liveTime ? (
                <>
                    <time
                        dateTime={`${formatDate(liveDateObj, "-")}T${openTime}`}
                    >
                        OPEN {openTime}
                    </time>
                    <span
                        style={{
                            margin: "0 5px",
                        }}
                    >
                        /
                    </span>
                    <time
                        dateTime={`${formatDate(
                            liveDateObj,
                            "-"
                        )}T${startTime}`}
                    >
                        START {startTime}
                    </time>
                </>
            ) : (
                <>未定</>
            )}
        </ArticleSection>
    )
}

type ActsSectionType = {
    liveActs?: ContentfulLives["liveActs"]
}

export const ActsSection: React.FC<ActsSectionType> = ({ liveActs }) => {
    return (
        <ArticleSection title="出演">
            {liveActs ? (
                <ul>
                    <li>
                        <p>yubiori</p>
                    </li>
                    {liveActs.map((act) => (
                        <li key={createToken()}>
                            <p>{act}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <>フライヤーの通り</>
            )}
        </ArticleSection>
    )
}

type LivePlaceSectionType = {
    livePlaceName?: ContentfulLives["livePlaceName"]
    placeMapRef: React.RefObject<HTMLDivElement>
}

export const LivePlaceSection: React.FC<LivePlaceSectionType> = ({
    livePlaceName,
    placeMapRef,
}) => {
    return (
        <ArticleSection title="会場">
            {livePlaceName ? (
                <div className="p-confirm__content">
                    <span className="u-mr-6">{livePlaceName}</span>
                    <BtnToScroll targetRef={placeMapRef}>
                        場所を確認する
                    </BtnToScroll>
                </div>
            ) : (
                <>未定</>
            )}
        </ArticleSection>
    )
}

type ArticleContentSectionType = {
    imageOnClick: (e: any) => void
    content?: ContentfulLivesLiveContent
}

export const ArticleContentSection: React.FC<ArticleContentSectionType> = ({
    imageOnClick,
    content,
}) => {
    return (
        <ArticleSection>
            <div className="u-mt-50px">
                <ArticleRichTextContent
                    imgOnClick={imageOnClick}
                    richTextData={content?.raw && JSON.parse(content.raw)}
                    references={content?.references as ContentfulAsset[]}
                />
            </div>
        </ArticleSection>
    )
}
