export const convToWeek = (date: Date): string => {
    if(date.toString() === 'Invalid Date') {
        console.error('convToWeek: 引数には正しいフォーマットで日付を設定してください')
        return '';
    }

    const week: string[] = [ "日", "月", "火", "水", "木", "金", "土" ];
    const dayIndex: number = date.getDay();

    return week[dayIndex];
}